import React, { useRef, useState, useEffect, useContext } from 'react'
import firebase,  { db, database } from './firebase.js';
import { useHistory } from 'react-router-dom';

import { ReadDistribution } from './database/distribution';
import { NoticeWrite } from './database/Notice';
import ReadTeams from './database/ReadTeams';
import ReadKamoku from './database/ReadKamoku';
import CreateAnbunLog from './database/CreateAnbunLog';
import ReadAnbunLog from './database/ReadAnbunLog';
import DeleteAnbunLog from './database/DeleteAnbunLog';
import DeleteAllocateCost from './database/DeleteAllocateCost';

import { useAuthContext } from './Auth';
import { Store } from './store';
import { ACTION , STORES } from './reducer';
import { MathRoundCustom } from './functional/MathRoundCustom'
import { TotalForKamoku } from './functional/TotalForKamoku'

import {
    CheckCircle,
    Error,
    HourglassBottom,
    Percent,
} from '@mui/icons-material';
import {
    makeStyles,
    createStyles,
    withStyles
} from "@mui/styles";
import {
    Button,
    Stack,
    Grid,
    TextField,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    SelectChangeEvent,
    Box,
    OutlinedInput,
    TablePagination,
} from '@mui/material';
import { LoadingButton } from '@mui/lab'

type Index = "MP" | "target" | "actual" | "expect" | "none";

const header = ['対象区分', 'パターンコード', '集計区分', '按分パターン名称'];

export const useStyles = makeStyles(() => 
    createStyles({
        loadingRotate: {
            animation: "spin 1.5s linear infinite",
            fontSize: "1.3rem",
        },
    })
);

function Distribution() {
    const { user }:any = useAuthContext();
    const { state, dispatch } = useContext(Store);

    const classes = useStyles();
    const logs = "logs_anbun";     // Realtime Database

    const inputRefYear = useRef<HTMLInputElement>(null);
    const inputRefMonth = useRef<HTMLInputElement>(null);

    const [page, setPage] = useState(0);                    // ページネーション用
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [logsKey, setLogsKey] = useState([]);
    const [logsList, setLogsList] = useState<any>();
    const [distributeLogs, setDistributeLogs] = useState<any>()

    const [syurui, setSyurui] = useState('');
    const [year, setYear] = useState('');
    const [month, setMonth] = useState('');
    const [ptnCode, setPtnCode] = useState('');

    const [inputErrorYear, setInputErrorYear] = useState(false);
    const [inputErrorMonth, setInputErrorMonth] = useState(false);
    const [infoMessage, setInfoMessage] = useState<any>({});

    const [status, setStatus] = useState("none")            // ロード画面表示用
    const [distributeLoading, setDistributeLoading] = useState(false)
    const [revertLoading, setRevertLoading] = useState(false)

    let history = useHistory();

    useEffect(() => {
        // ログリスト取得
        database.ref(logs)
        .on('value', (snapshot) => {
            if (snapshot.val()) {
                const l:any = []
                Object.keys(snapshot.val()).forEach((key) => {
                  const tmpObj:any = {}
                  tmpObj[key] = snapshot.val()[key]
                  l.push(tmpObj)
                })
                let reversedList = l.slice().reverse();
                setPageCount(reversedList.length)

                let reversedData:any = {}
                reversedList.map((row:any) => {
                    const key:any = Object.keys(row)
                    const value:any = snapshot.val()[key]
                    reversedData = { ...reversedData, [key] : {...value} }
                })
                setLogsList(reversedData)
                setPageCount(reversedList.length)
                setLogsKey(reversedList)

                // 初回表示用のログを抽出
                const startIndex:number = page * 10; 
                const endIndex:number = (rowsPerPage * page) + rowsPerPage - 1
                let data:any = {}
                let keys:Array<string> = [];        
                // 表示するターゲットのキー値を取得
                for ( let i = startIndex; i <= endIndex; i++ ) {
                    if ( i > reversedList.length - 1 ) { break }
                    let targetKey:Array<string> = Object.keys(reversedList[i])
                    keys.push(...targetKey)
                }
                for ( let i = 0; i < keys.length; i++ ) {
                    data = { ...data, [keys[i]] : reversedData[keys[i]] }
                }    
                setDistributeLogs(data)
            }
        })
    }, [])

    const formChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch ( e.target.name ) {
            case 'patterncode': {
                setPtnCode(e.target.value)
                break;
            }
            case 'year': {
                if ( inputRefYear.current ) {
                    const ref = inputRefYear.current;
                    ref.setCustomValidity('')
                    if ( !ref.validity.valid ) {
                        ref.setCustomValidity('数字を入力してください。')
                        setInputErrorYear(true)
                    } else {
                        setInputErrorYear(false)
                        setYear(e.target.value)
                    }
                }
                break;
            }
            case 'month': {
                if ( inputRefMonth.current ) {
                    const ref = inputRefMonth.current;
                    ref.setCustomValidity('')
                    if ( !ref.validity.valid ) {
                        ref.setCustomValidity('数字を入力してください。')
                        setInputErrorMonth(true)
                    } else {
                        setInputErrorMonth(false)
                        setMonth(e.target.value)
                    }
                }
                break;
            }
        }
    }
    
    const handleChange = (event: SelectChangeEvent) => {
        console.log(event.target)
        setSyurui(event.target.value as string)
    }

    const distributeButtonTrigger = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // MPの2023年以前の按分or按分解除が指定されたらエラーで返すようにする
        //   データ移行の際に、本来の取り込み方とは違う取り込み方をしているので
        if ( syurui === "MP" && Number(year) <= 2023 ) {
            // ログ保管（RealtimeDatabase）
            setInfoMessage({ year: year, month: month, syurui: syurui })
            const nowTime:any = new Date();
            const diff = nowTime.getTimezoneOffset() * 60 * 1000
            const plusLocal = new Date(nowTime - diff)
            const convPlusLocal = plusLocal.toISOString()
            const editPlusLocal = convPlusLocal.replace("T", " ")
            const nowLocal = editPlusLocal.substring(0, editPlusLocal.indexOf("."))
            // エラー終了（ログ保管）
            database.ref(logs).push({
                type: "-",
                result: "失敗",
                date: nowLocal,
                user: user.displayName,
                targetYearMonth: year+"年"+month+"月",
                syurui: syurui,
                description: `アメーバからのデータ移行の都合により、MPの2023年以前のデータは按分と按分解除することができません`
            })
            return
        }

        const docId = year + syurui
        const target:any = e.nativeEvent;
        const buttonName:string = target.submitter.name;

        let taskId:string = "";
        if ( Object.keys(state).length === 0 ) {
            taskId = "1"
        } else {
            let taskIdNum:number = Number(Object.keys(state).reverse()[0])
            taskIdNum++
            taskId = String(taskIdNum)
        }

        switch (buttonName) {
            case "anbun" :
                setStatus("loading")
                setDistributeLoading(true)

                DistributeSwitch(
                    docId,
                    month,
                    year,
                    syurui,
                    dispatch,
                    taskId,
                    setStatus,
                    setDistributeLoading,
                    setInfoMessage,
                    user,
                    logs,
                )
                .then(() => setDistributeLoading(false))
                .catch((e) => setDistributeLoading(false))
                break;
            case "revert" : 
                setStatus("loading")
                setRevertLoading(true)

                RevertSwitch(
                    month,
                    year,
                    syurui,
                    dispatch,
                    taskId,
                    setStatus,
                    setRevertLoading,
                    setInfoMessage,
                    user,
                    logs,
                )
                .then(() => setRevertLoading(false))
                .catch((e) => setRevertLoading(false))
                break;
        }
    }

    // 履歴ページネーション関数（履歴取得）
    const viewLogsList = (newPage:number, perPage:number) => {
        const startIndex:number = newPage * perPage; 
        let endIndex:number = (perPage * newPage) + perPage - 1
        let data:any = {}
        let keys:Array<string> = [];

        // 表示するターゲットのキー値を取得
        for ( let i = startIndex; i <= endIndex; i++ ) {
            if ( i > logsKey.length -1 ) { break }
            let targetKey:Array<string> = Object.keys(logsKey[i])
            keys.push(...targetKey)
        }
        console.log(keys)

        for ( let i = 0; i < keys.length; i++ ) {
            data = { ...data, [keys[i]] : logsList[keys[i]] }
        }
        console.log(data)
        setDistributeLogs(data)    
    }

    // 履歴ページネーション関数（ページ移動処理）
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage)
        viewLogsList(newPage, rowsPerPage)
    }

    // 履歴ページネーション関数（1ページ当たりの表示件数処理）
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const responseLoading = (status:string) => {
        switch (status) {
            case "none":
                return (
                    <div className="notice" style={{ fontSize:"0.75rem", border: "solid 1px #eee", marginTop: 16, paddingLeft: 24, paddingRight: 24 }}>
                        <p>「按分」または「按分解除」ボタンをクリックしてください。</p>
                    </div>
                )
            case "loading":
                return (
                    <div className="notice" style={{ fontSize:"0.75rem", border: "solid 1px #eee", marginTop: 16, paddingLeft: 24, paddingRight: 24 }}>
                        <div style={{ float:"left", paddingRight:8, paddingTop: 16 }}>
                            <HourglassBottom className={classes.loadingRotate} />
                            <style>{`
                                @keyframes spin {
                                    100% { transform: rotate(360deg); }
                                    0% { transform: rotate(0deg); }
                                }
                            `}</style>
                        </div>
                        <p style={{ paddingTop:8, paddingBottom: 6 }}>データベースを更新しています...</p>
                    </div>
                )
            case "normalEnd":
                return (
                    <div className="notice" style={{ fontSize:"0.75 rem", border: "solid 1px #eee", marginTop: 16, paddingLeft: 24, paddingRight: 24 }}>
                        <div style={{ float:"left", paddingRight:4, paddingTop: 10 }}>
                            <CheckCircle color='success' style={{ float:"left", fontSize:18, marginRight:4, }} />
                        </div>
                        <p>処理が完了しました。</p>
                        <p>対象年月：{infoMessage.year}年 {infoMessage.month}月</p>
                    </div>
                )
            case "errorEnd":
                return (
                    <div className="notice" style={{ fontSize:"0.75rem", border: "solid 1px #eee", marginTop: 16, paddingLeft: 24, paddingRight: 24 }}>
                        <div style={{ float:"left", paddingRight:4, paddingTop: 10 }}>
                            <Error color='error' style={{ float:"left", fontSize:18, marginRight:4, }} />
                        </div>
                        <p>処理に失敗しました。</p>
                        <p>{infoMessage.errMessage}</p>
                    </div>
                )
            default:
                console.log('switch構文がいとうなし')
                break;
        }
    }

    const anbunViewTrigger = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        const anbunLogID = e.currentTarget.id
        const targetAnbunLog = distributeLogs[anbunLogID]
        let yearKey = ""
        let monthKey = ""
        let targetYearMonth = distributeLogs[anbunLogID].targetYearMonth
        targetYearMonth = targetYearMonth.replace(/[^0-9]/g, '')
        let categoryKey = targetAnbunLog.syurui
        const transactionId = distributeLogs[anbunLogID].transactionId

        if ( targetYearMonth.length <= 5 ) {
            yearKey = targetYearMonth.substr(0, 4)
            monthKey = `${targetYearMonth.substr(4, 1)}`
        } else {
            yearKey = targetYearMonth.substr(0, 4)
            monthKey = targetYearMonth.substr(4, 2)
        }
        console.log(yearKey, monthKey)

        let message = {
            "id": transactionId,
            "year": yearKey,
            "month": monthKey,
            "category": categoryKey,
        }
        console.log(message)

        history.push({ state: { ...message } })
        console.log(history)

        const URL = `${process.env.REACT_APP_URL}/v/distribution`;
        const popup = window.open(URL, "_blank", "menubar=no, width=1300, height=600")
        if ( popup ) {
            popup.onload = () => {
                popup.postMessage(message, URL)     // onloadって書くと、子ウインドウが読み込まれた後にメッセージが送信される
            }
        }
    }

    return (
        <>
        <div style={ { marginRight: 48, marginLeft: 48 }}>
            <div style={{ float:"left", paddingRight:8 }}>
                <Percent style={{ fontSize:36 }} />
            </div>
            <h2 style={{ marginBlockEnd: 8, paddingTop:6 }}>按分処理</h2>
            <p style={{ marginBlockStart: 8 }}>MP、予定、見込、実績の採算表を按分できます。</p>
            <div className="noticeWrapper" style={{ marginTop: 32, marginBottom: 16 }}>
                <form onSubmit={distributeButtonTrigger}>
                    <Stack spacing={1}>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Box sx={{ minWidth:120 }}>
                                    <FormControl variant="outlined" fullWidth size="small">
                                        <InputLabel id="select-label" shrink>種類</InputLabel>
                                        <Select
                                            labelId="select-label"
                                            id="syurui-select"
                                            value={syurui}
                                            onChange={handleChange}
                                            input={
                                                <OutlinedInput
                                                    notched
                                                    label="種類"
                                                />
                                            }
                                        >
                                            <MenuItem value="MP" id="mp">MP</MenuItem>
                                            <MenuItem value="予定" id="target">予定</MenuItem>
                                            <MenuItem value="実績" id="actual">実績</MenuItem>
                                            <MenuItem value="見込" id="expect">見込</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            {/* <Grid item>
                                <TextField
                                    id="outlined-search"
                                    name="patterncode"
                                    label="パターンコード"
                                    type="text"
                                    size="small"
                                    onChange={formChange}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid> */}
                            <Grid item>
                                <TextField
                                    id="outlined-search"
                                    name="year"
                                    label="年度"
                                    type="text"
                                    size="small"
                                    error={inputErrorYear}
                                    inputProps={{ maxLength:4, pattern:"^[0-9]+$" }}
                                    inputRef={inputRefYear}
                                    helperText={inputRefYear?.current?.validationMessage}
                                    onChange={formChange}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item>
                                { syurui !== "MP" 
                                ?
                                <TextField
                                    id="outlined-search"
                                    name="month"
                                    label="月度"
                                    type="text"
                                    size="small"
                                    error={inputErrorMonth}
                                    inputProps={{ maxLength:2, pattern:"^[0-9]+$" }}
                                    inputRef={inputRefMonth}
                                    helperText={inputRefMonth?.current?.validationMessage}
                                    onChange={formChange}
                                    InputLabelProps={{ shrink: true }}
                                />
                                :
                                <></>
                                }
                            </Grid>
                            <Grid item>
                                <LoadingButton
                                    name="anbun"
                                    disabled={ inputErrorMonth || inputErrorYear || revertLoading }
                                    variant='contained'
                                    loading={distributeLoading}
                                    type="submit"
                                >
                                    按分
                                </LoadingButton>
                            </Grid>
                            <Grid item>
                                <LoadingButton
                                    name="revert"
                                    disabled={ inputErrorMonth || inputErrorYear || distributeLoading }
                                    variant='outlined'
                                    loading={revertLoading}
                                    type="submit"
                                >
                                    按分解除
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Stack>
                </form>
                {responseLoading(status)}
                <div className="logs" style={{ marginTop: 48, marginBottom: 16 }}>
                    <h3 style={{ borderLeft: "solid 3px rgba(0, 0, 0, 0.6)", paddingLeft: 6, lineHeight: 1.75 }}>按分履歴</h3>
                    { distributeLogs !== undefined ?
                    <div style={{ width:'100%' }}>
                        <TablePagination
                            component="div"
                            count={pageCount}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        style={{ backgroundColor: '#e3f2fd', height: 32 }}
                                    >
                                        <TableCell align='left' style={{ width: 40, maxWidth: 40, padding: "0px 16px" }}>実施日付</TableCell>
                                        <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>実施結果</TableCell>
                                        <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>種別</TableCell>
                                        <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>対象年月</TableCell>
                                        <TableCell align='left' style={{ width: 15, maxWidth: 15, padding: "0px 16px" }}>種類</TableCell>
                                        <TableCell align='left' style={{ width: 15, maxWidth: 15, padding: "0px 16px" }}>ユーザー</TableCell>
                                        <TableCell align='left' style={{ width: 50, maxWidth: 50, padding: "0px 16px" }}>内容</TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </TableContainer>
                        { Object.keys(distributeLogs).map((key:any, id:any)=>
                            <Table>
                                <TableBody>
                                    <TableRow
                                        hover
                                        id={id}
                                        role="checkbox"
                                        tabIndex={-1}
                                        style={{ height: 32 }}
                                    >
                                        <TableCell align='left' style={{ width: 40, maxWidth: 40, padding: "0px 16px" }}>{distributeLogs[key].date}</TableCell>
                                        { distributeLogs[key].result === '成功' ?
                                            <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>
                                                <CheckCircle color='success' style={{ float:"left", fontSize:18, marginRight:4, }} />
                                                <div style={{}}>{distributeLogs[key].result}</div>
                                            </TableCell>
                                        :
                                            <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>
                                                <Error color='error' style={{ float:"left", fontSize:18, marginRight:4, }} />
                                                <div style={{}}>{distributeLogs[key].result}</div>
                                            </TableCell>
                                        }
                                        <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>{distributeLogs[key].type}</TableCell>
                                        <TableCell align='left' style={{ width: 10, maxWidth: 10, padding: "0px 16px" }}>{distributeLogs[key].targetYearMonth}</TableCell>
                                        <TableCell align='left' style={{ width: 15, maxWidth: 15, padding: "0px 16px" }}>{distributeLogs[key].syurui}</TableCell>
                                        <TableCell align='left' style={{ width: 15, maxWidth: 15, padding: "0px 16px" }}>{distributeLogs[key].user}</TableCell>
                                        { distributeLogs[key].result === "失敗" || distributeLogs[key].type === "按分解除"
                                        ?
                                        <TableCell align='left' style={{ width: 50, maxWidth: 50, padding: "0px 16px" }}>
                                            {distributeLogs[key].description}
                                        </TableCell>
                                        : 
                                        <TableCell align='left' style={{ width: 50, maxWidth: 50, padding: "0px 16px" }}>
                                            <Button
                                                id={key}
                                                variant="outlined"
                                                onClick={(e) => {anbunViewTrigger(e)}}
                                                style={{
                                                    fontSize: "12px",
                                                    maxWidth:'144px',
                                                    maxHeight:'24px',
                                                    minWidth:'144px',
                                                    minHeight:'24px',
                                                }}
                                            >
                                                詳細確認
                                            </Button>
                                        </TableCell>
                                        }
                                    </TableRow>
                                </TableBody>
                            </Table>
                        )}
                    </div>
                    :
                    <span>履歴がありません</span>
                    }
                </div>
            </div>
        </div>
        </>
    )
}
export default Distribution;

async function DistributeSwitch (
    docId:string,
    month:string,
    year:string,
    syurui:string,
    dispatch:any,
    taskId:any,
    setStatus:any,
    setDistributeLoading:any,
    setInfoMessage:any,
    user:any,
    logs:any,
) {
    if ( syurui === "MP" ) {
        console.log(`Selected MP.`)

        for ( let i = 1 ; i <= 12 ; i++ ) {
            let mpMonth = String(i)
            let isError = false

            console.log(`MP ${year}年${mpMonth}月 Started.`);
            await Distribute(docId, mpMonth, year, syurui, dispatch, taskId)
            .then((id) => {
                console.log('anbun success')
                console.log(id)
                setStatus("normalEnd")
                
                // 新着通知をとばす
                dispatch({ type: "END", status:"success", key:taskId })
                NoticeWrite(
                    user.uid,
                    `按分が完了しました\n - ${year}年${mpMonth}月 ${syurui}`,
                    "anbun",
                    "success"
                )
                dispatch({ type:"INITIAL", key:taskId })

                // ログ保管テスト（RealtimeDatabase）
                setInfoMessage({ year: year, month: mpMonth, syurui: syurui })
                const nowTime:any = new Date();
                const diff = nowTime.getTimezoneOffset() * 60 * 1000
                const plusLocal = new Date(nowTime - diff)
                const convPlusLocal = plusLocal.toISOString()
                const editPlusLocal = convPlusLocal.replace("T", " ")
                const nowLocal = editPlusLocal.substring(0, editPlusLocal.indexOf("."))
                // 正常終了（ログ保管）
                database.ref(logs).push({
                    type: "按分",
                    result: "成功",
                    date: nowLocal,
                    user: user.displayName,
                    targetYearMonth: year+"年"+mpMonth+"月",
                    syurui: syurui,
                    description: "",
                    transactionId: id,
                })
            })
            .catch((error) => {
                console.log(error)
                setStatus("errorEnd")

                // 新着通知をとばす
                dispatch({ type: "END", status:"error", key:taskId })
                NoticeWrite(
                    user.uid,
                    `按分が失敗しました\n - ${year}年${mpMonth}月 ${syurui}`,
                    "anbun",
                    "error"
                )
                dispatch({ type:"INITIAL", key:taskId })

                // ログ保管テスト（RealtimeDatabase）
                setInfoMessage({ year: year, month: mpMonth, syurui: syurui })
                const nowTime:any = new Date();
                const diff = nowTime.getTimezoneOffset() * 60 * 1000
                const plusLocal = new Date(nowTime - diff)
                const convPlusLocal = plusLocal.toISOString()
                const editPlusLocal = convPlusLocal.replace("T", " ")
                const nowLocal = editPlusLocal.substring(0, editPlusLocal.indexOf("."))
                // エラー終了（ログ保管）
                database.ref(logs).push({
                    type: "按分",
                    result: "失敗",
                    date: nowLocal,
                    user: user.displayName,
                    targetYearMonth: year+"年"+mpMonth+"月",
                    syurui: syurui,
                    description: error
                })
                isError = true
            })
            if ( isError ) break
            console.log(`MP ${year}年${mpMonth}月 Successed.`)
        }
    } else {
        console.log(`Selected YOTEI or MIKOMI.`)
        await Distribute(docId, month, year, syurui, dispatch, taskId)
        .then((id) => {
            setStatus("normalEnd")
            console.log(id)

            // 新着通知をとばす
            dispatch({ type: "END", status:"success", key:taskId })
            NoticeWrite(
                user.uid,
                `按分が完了しました\n - ${year}年${month}月 ${syurui}`,
                "anbun",
                "success"
            )
            dispatch({ type:"INITIAL", key:taskId })

            // ログ保管テスト（RealtimeDatabase）
            setInfoMessage({ year: year, month: month, syurui: syurui })
            const nowTime:any = new Date();
            const diff = nowTime.getTimezoneOffset() * 60 * 1000
            const plusLocal = new Date(nowTime - diff)
            const convPlusLocal = plusLocal.toISOString()
            const editPlusLocal = convPlusLocal.replace("T", " ")
            const nowLocal = editPlusLocal.substring(0, editPlusLocal.indexOf("."))
            // 正常終了（ログ保管）
            database.ref(logs).push({
                type: "按分",
                result: "成功",
                date: nowLocal,
                user: user.displayName,
                targetYearMonth: year+"年"+month+"月",
                syurui: syurui,
                description: "",
                transactionId: id,
            })
        })
        .catch((error) => {
            console.log(error)
            setStatus("errorEnd")

            // 新着通知をとばす
            dispatch({ type: "END", status:"error", key:taskId })
            NoticeWrite(
                user.uid,
                `按分が失敗しました\n - ${year}年${month}月 ${syurui}`,
                "anbun",
                "error"
            )
            dispatch({ type:"INITIAL", key:taskId })

            // ログ保管テスト（RealtimeDatabase）
            setInfoMessage({ year: year, month: month, syurui: syurui })
            const nowTime:any = new Date();
            const diff = nowTime.getTimezoneOffset() * 60 * 1000
            const plusLocal = new Date(nowTime - diff)
            const convPlusLocal = plusLocal.toISOString()
            const editPlusLocal = convPlusLocal.replace("T", " ")
            const nowLocal = editPlusLocal.substring(0, editPlusLocal.indexOf("."))
            // エラー終了（ログ保管）
            database.ref(logs).push({
                type: "按分",
                result: "失敗",
                date: nowLocal,
                user: user.displayName,
                targetYearMonth: year+"年"+month+"月",
                syurui: syurui,
                description: error
            })
        })    
    }
}

async function RevertSwitch (
    month:string,
    year:string,
    syurui:string,
    dispatch:any,
    taskId:any,
    setStatus:any,
    setRevertLoading:any,
    setInfoMessage:any,
    user:any,
    logs:any,
) {
    if ( syurui === "MP" ) {
        console.log(`Selected MP.`)
        for ( let i = 1 ; i <= 12 ; i++ ) {
            let mpMonth = String(i)
            let isError = false
            await revertToDistribute(mpMonth, year, syurui, dispatch, taskId)
            .then(() => {
                console.log('lift success')
                setStatus("normalEnd")
        
                // 新着通知をとばす
                dispatch({ type: "END", status:"success", key:taskId })
                NoticeWrite(
                    user.uid,
                    `按分解除が完了しました\n - ${year}年${mpMonth}月 ${syurui}`,
                    "anbun",
                    "success"
                )
                dispatch({ type:"INITIAL", key:taskId })
        
                // ログ保管テスト（RealtimeDatabase）
                setInfoMessage({ year: year, month: mpMonth, syurui: syurui })
                const nowTime:any = new Date();
                const diff = nowTime.getTimezoneOffset() * 60 * 1000
                const plusLocal = new Date(nowTime - diff)
                const convPlusLocal = plusLocal.toISOString()
                const editPlusLocal = convPlusLocal.replace("T", " ")
                const nowLocal = editPlusLocal.substring(0, editPlusLocal.indexOf("."))
                // 正常終了（ログ保管）
                database.ref(logs).push({
                    type: "按分解除",
                    result: "成功",
                    date: nowLocal,
                    user: user.displayName,
                    targetYearMonth: `${year}年${mpMonth}月`,
                    syurui: syurui,
                    description: ""
                })
            })
            .catch((error) => {
                console.log(error)
                setStatus("errorEnd")
        
                // 新着通知をとばす
                dispatch({ type: "END", status:"error", key:taskId })
                NoticeWrite(
                    user.uid,
                    `按分解除が失敗しました\n - ${year}年${mpMonth}月 ${syurui}`,
                    "anbun",
                    "error"
                )
                dispatch({ type:"INITIAL", key:taskId })
        
                // ログ保管テスト（RealtimeDatabase）
                setInfoMessage({ year: year, month: mpMonth, syurui: syurui })
                const nowTime:any = new Date();
                const diff = nowTime.getTimezoneOffset() * 60 * 1000
                const plusLocal = new Date(nowTime - diff)
                const convPlusLocal = plusLocal.toISOString()
                const editPlusLocal = convPlusLocal.replace("T", " ")
                const nowLocal = editPlusLocal.substring(0, editPlusLocal.indexOf("."))
                // エラー終了（ログ保管）
                database.ref(logs).push({
                    type: "按分解除",
                    result: "失敗",
                    date: nowLocal,
                    user: user.displayName,
                    targetYearMonth: `${year}年${mpMonth}月`,
                    syurui: syurui,
                    description: error
                })
                isError = true
            })
            if ( isError ) break
        }
    } else {
        console.log(`Selected YOTEI or MIKOMI.`)
        await revertToDistribute(month, year, syurui, dispatch, taskId)
        .then(() => {
            console.log('lift success')
            setStatus("normalEnd")
    
            // 新着通知をとばす
            dispatch({ type: "END", status:"success", key:taskId })
            NoticeWrite(
                user.uid,
                `按分解除が完了しました\n - ${year}年${month}月 ${syurui}`,
                "anbun",
                "success"
            )
            dispatch({ type:"INITIAL", key:taskId })
    
            // ログ保管テスト（RealtimeDatabase）
            setInfoMessage({ year: year, month: month, syurui: syurui })
            const nowTime:any = new Date();
            const diff = nowTime.getTimezoneOffset() * 60 * 1000
            const plusLocal = new Date(nowTime - diff)
            const convPlusLocal = plusLocal.toISOString()
            const editPlusLocal = convPlusLocal.replace("T", " ")
            const nowLocal = editPlusLocal.substring(0, editPlusLocal.indexOf("."))
            // 正常終了（ログ保管）
            database.ref(logs).push({
                type: "按分解除",
                result: "成功",
                date: nowLocal,
                user: user.displayName,
                targetYearMonth: year+"年"+month+"月",
                syurui: syurui,
                description: ""
            })
        })
        .catch((error) => {
            console.log(error)
            setStatus("errorEnd")
    
            // 新着通知をとばす
            dispatch({ type: "END", status:"error", key:taskId })
            NoticeWrite(
                user.uid,
                `按分解除が失敗しました\n - ${year}年${month}月 ${syurui}`,
                "anbun",
                "error"
            )
            dispatch({ type:"INITIAL", key:taskId })
    
            // ログ保管テスト（RealtimeDatabase）
            setInfoMessage({ year: year, month: month, syurui: syurui })
            const nowTime:any = new Date();
            const diff = nowTime.getTimezoneOffset() * 60 * 1000
            const plusLocal = new Date(nowTime - diff)
            const convPlusLocal = plusLocal.toISOString()
            const editPlusLocal = convPlusLocal.replace("T", " ")
            const nowLocal = editPlusLocal.substring(0, editPlusLocal.indexOf("."))
            // エラー終了（ログ保管）
            database.ref(logs).push({
                type: "按分解除",
                result: "失敗",
                date: nowLocal,
                user: user.displayName,
                targetYearMonth: year+"年"+month+"月",
                syurui: syurui,
                description: error
            })
        })
    }
}

async function Distribute (docid: string, month: string, year: string, syurui: string, dispatch:React.Dispatch<ACTION>, taskId:string) {
    /////////////////////////////////////  START   //////////////////////////////////////
    // 
    // 1. 按分 (fractionTeamCodeは必ず設定されてる想定)
    // 
    // 
    // STEP1.   JSONファイルから按分元になる採算科目の数値を取得 (sourceの処理)
    // STEP2.   按分率の分母になる数値(各チームの時間合計(4010))の計算 (destの処理)
    //          - JSONファイルのdestTeamCodeを順になめていく
    // STEP3.   按分率(構成比率)の算出 , 按分の実施
    //          - 端数負担するチームが整数の場合は計算結果をそのまま配列に代入
    // STEP4.   按分結果の書き込み
    // STEP5.   按分元の採算科目と関係する採算科目の数値を 0 にする (sourceの処理)
    //          - 採算科目のJSONファイルから計算に使われている採算科目の配列(formulaList)を取得して 0 にする
    // STEP6.   按分元のチームが上位組織だった場合の処理（A1000が按分される時を想定）
    // STEP7.   親ドキュメントへの集計処理
    //
    //////////////////////////////////////////////////////////////////////////////////////
    // bugfix-#277 START 複数回の按分実行防止エラー
    const anbunLogProp = await ReadAnbunLog(syurui, year, month)
    if ( anbunLogProp.id !== "" ) throw `同じ組み合わせでの按分結果が既に存在します。`          // 按分結果が存在する場合は処理を終了する
    // bugfix-#277 E N D 複数回の按分実行防止エラー

    const distributions = await ReadDistribution();
    const kamoku:any    = await ReadKamoku();
    const teams:any  = await ReadTeams();
    const key:Index = (() => {
        switch (syurui) {
            case "MP" : return "MP"
            case "予定" : return "target"
            case "実績" : return "actual"
            case "見込" : return "expect"
            default : return "none"
        }
    })()
    const dList = distributions[key];                   // 採算表の種類別の按分配列
    let teamcodeListForUpdate:any = [];                // DB更新用チームコード保持配列
    let dataListForUpdate:any = [];                // DB更新用データ保持配列

    let anbunProp:any = {}                      // bugfix-#277 配賦される数値のみを保持
    let anbunLogPropOutputs:any = []
    let transactionID = KeyGenerate()           // 按分結果識別用
console.log(transactionID)

    let maxCount:number = dList.length + Object.keys(teams).length + Object.keys(teams).length        // 按分パターンの数 + 集計処理対象チームコード数 + DB更新対象チームコード数
    dispatch({ type:"WAIT_FUNCTIONS", maxCount:maxCount, status:"loading", key:taskId })

    for ( let r = 0; r < dList.length; r++ ) {
        // storeのアップデート呼ぶときに使う（月別docのキー名称変えるために使う）
        let distributionEnabled = dList[r].kubun === 0 ? false : true       // 0:按分しない（100%振替）、 1:按分する
        let sourceTeamCode = dList[r].sourceTeamCode;
        let sourceKamoku = dList[r].sourceKamokuCode;
        let destTeamCode = dList[r].destTeamCode;
        let destKamoku = dList[r].destKamokuCode;
        let fractionTeamCode:any = dList[r].fractionTeamCode;
        let patternCode = dList[r].patternCode;
        let fractionIndex = fractionTeamCode != undefined ? destTeamCode.indexOf(fractionTeamCode) : undefined;      // 端数負担チームの配列インデックス返す
        let isHigherCode = false;                             // 下位組織のマイナス処理の判断フラグ
        let relationCodeList:Array<string> = [];
        let sourceTeamCodeList:Array<string> = [];

        // 按分元のチームが上位組織の場合、下位組織を取得し順に按分元にして按分していく想定
        if ( teams[sourceTeamCode].relationCode ) {
            isHigherCode = true;
            sourceTeamCodeList.push(sourceTeamCode)
            relationCodeList.push(...teams[sourceTeamCode].relationCode)
        } else {
            sourceTeamCodeList.push(sourceTeamCode)
        }

        // 上位組織だった場合は、按分元を下位組織に変更し順になめていく
        // If the code was higher organization, change the code to lower organization.
        for ( let m = 0; m < sourceTeamCodeList.length; m++ ) {
            sourceTeamCode = sourceTeamCodeList[m];

            let teamTime:Array<number> = [];
            let anbunSource = 0;                            // 按分される採算科目の数値
            let anbunRateList:Array<number> = [];           // チームごとの按分率の配列
            let anbunAfterList:Array<number> = [];          // チーム毎の按分後の数値
            let destData:any = [];                          // 按分後のデータ格納用配列
            let sourceData:any = {};
            let relCodeList:any = [];
            let relDataList:any = [];
            const source = db.collection("amoebaList").doc(sourceTeamCode).collection("Saisan").doc(docid).collection("Month").doc(month);

            if ( !distributionEnabled ) {       // 按分しない場合（100%振替）
                await db.runTransaction(async transaction => {
                    // STEP1
                    if ( teamcodeListForUpdate.includes(sourceTeamCode) ) {
                        let sourceIdx:number = teamcodeListForUpdate.indexOf(sourceTeamCode)
                        sourceData = dataListForUpdate[sourceIdx]
                        anbunSource += sourceData[sourceKamoku].value
                    } else {
                        await transaction.get(source)
                        .then((sourceDoc) => {
                            let data = sourceDoc.get('data')
                            //
                            let initData:any = {};
                            Object.keys(kamoku).map((code:string) => {
                                if ( code === '9999' ) {
                                    initData = { ...initData, [code] : {'value' : 100}}
                                } else {
                                    initData = { ...initData, [code] : {'value' : 0}}
                                }
                            })
                            Object.keys(data).map(code => {
                                if ( code === '9999' ) return
                                initData[code].value += data[code].value
                            })
                            //
                            // sourceData = {...data}
                            // anbunSource += data[sourceKamoku].value
                            sourceData = {...initData}
                            anbunSource += initData[sourceKamoku].value
                        })
                        .catch(error => { throw `採算表を取得できませんでした。按分パターン:${patternCode} 按分元:${sourceTeamCode}` })
                    }

                    // STEP2.
                    for ( let i = 0 ; i < destTeamCode.length ; i++ ) {
                        if ( teamcodeListForUpdate.includes(destTeamCode[i]) ) {
                            let destIdx:number = teamcodeListForUpdate.indexOf(destTeamCode[i])
                            destData[i] = dataListForUpdate[destIdx]
                        } else {
                            const dest = db.collection("amoebaList").doc(destTeamCode[i]).collection("Saisan").doc(docid).collection("Month").doc(month);

                            await transaction.get(dest)
                            .then((destDoc) => {
                                let data = destDoc.get('data')
                                let initData:any = {};
                                Object.keys(kamoku).map((code:string) => {
                                    if ( code === '9999' ) {
                                        initData = { ...initData, [code] : {'value' : 100}}
                                    } else {
                                        initData = { ...initData, [code] : {'value' : 0}}
                                    }
                                })
                                Object.keys(data).map(code => {
                                    if ( code === '9999' ) return
                                    initData[code].value += data[code].value
                                })
                                destData.push(initData)
                            })
                            .catch(error => { throw `採算表を取得できませんでした。按分パターン:${patternCode} 按分先:${destTeamCode[i]}` })
                        }
                    };

                    // // bugfix-#277 START
                    // 按分結果の値生成
                    anbunProp = CreateAnbunProp(
                        anbunSource,
                        sourceTeamCode,
                        sourceKamoku,
                        destTeamCode,
                        destKamoku,
                        anbunSource,        // 固定振替の場合は按分元の数値をそのまま流用
                        anbunProp,
                    )

                    let anbunResultLog = CreateAnbunLogs(
                        patternCode,
                        sourceTeamCode,
                        sourceKamoku,
                        anbunSource,
                        destTeamCode,
                        destKamoku,
                        anbunSource,
                        1,
                        [0],
                        0,
                        true
                    )
                    anbunLogPropOutputs.push(anbunResultLog)
                    // // bugfix-#277 E N D

                    // STEP4.
                    for ( let i = 0 ; i < destTeamCode.length ; i++ ) {
                        destData[i][destKamoku].value += anbunSource;
                        let dataProp = await CreateProp(destData[i])
                        destData[i] = dataProp.anbundata
                    };

                    // STEP5.
                    sourceData[sourceKamoku].value = 0;
                    let formulaList: Array<string> = kamoku[sourceKamoku].formulaList;
                    if ( formulaList !== undefined ) {
                        formulaList.map((kamoku) => { sourceData[kamoku].value = 0 });
                    }

                    let dataProp = await CreateProp(sourceData)
                    sourceData = dataProp.anbundata
                })
                .then(() => { console.log(`100% kotei success ${patternCode}`) })
                .catch(error => { throw error })
            } else {    // 按分する場合
                await db.runTransaction(async transaction => {
                    // STEP1
                    if ( teamcodeListForUpdate.includes(sourceTeamCode) ) {
                        let sourceIdx:number = teamcodeListForUpdate.indexOf(sourceTeamCode)
                        sourceData = dataListForUpdate[sourceIdx]
                        anbunSource += sourceData[sourceKamoku].value
                        // console.log(sourceData[sourceKamoku])
                    } else {
                        await transaction.get(source)
                        .then((sourceDoc) => {
                            let data = sourceDoc.get('data')
                            let initData:any = {};
                            Object.keys(kamoku).map((code:string) => {
                                if ( code === '9999' ) {
                                    initData = { ...initData, [code] : {'value' : 100}}
                                } else {
                                    initData = { ...initData, [code] : {'value' : 0}}
                                }
                            })
                            Object.keys(data).map(code => {
                                if ( code === '9999' ) return
                                initData[code].value += data[code].value
                            })
                            sourceData = {...initData}
                            anbunSource += initData[sourceKamoku].value
                        })
                        .catch(error => { throw `採算表を取得できませんでした。按分パターン:${patternCode} 按分元:${sourceTeamCode}` })
                    }
                    console.log('按分する採算科目 : ' + anbunSource)
            
                    // STEP2.
                    let totalTime: number = 0;
                    let sourceDistributionRatio:string = "4010";                // issue #33, #137 
                    for ( let i = 0 ; i < destTeamCode.length ; i++ ) {
                        if ( teamcodeListForUpdate.includes(destTeamCode[i]) ) {
                            let destIdx:number = teamcodeListForUpdate.indexOf(destTeamCode[i])
                            destData[i] = dataListForUpdate[destIdx]
                            teamTime.push(destData[i][sourceDistributionRatio].value)     // 各チームの時間を配列に格納（teamTime）
                            totalTime += destData[i][sourceDistributionRatio].value       // 分母になる各チームの総時間計を計算(totalTime)
                        } else {
                            const dest = db.collection("amoebaList").doc(destTeamCode[i]).collection("Saisan").doc(docid).collection("Month").doc(month);

                            await transaction.get(dest)
                            .then((document) => {
                                let data = document.get('data')
                                let initData:any = {};
                                Object.keys(kamoku).map((code:string) => {
                                    if ( code === '9999' ) {
                                        initData = { ...initData, [code] : {'value' : 100}}
                                    } else {
                                        initData = { ...initData, [code] : {'value' : 0}}
                                    }
                                })
                                Object.keys(data).map(code => {
                                    if ( code === '9999' ) return
                                    initData[code].value += data[code].value
                                })
                                destData.push(initData)
                                teamTime.push(initData[sourceDistributionRatio].value)     // 各チームの時間を配列に格納（teamTime）
                                totalTime += initData[sourceDistributionRatio].value       // 分母になる各チームの総時間計を計算(totalTime)
                            })
                            .catch(error => { throw `採算表を取得できませんでした。按分パターン:${dList[r].patternCode} 按分先:${destTeamCode[i]}` })
                        }
                    };
                    totalTime = MathRoundCustom(totalTime*100)
                    totalTime = Math.trunc(totalTime)/100;      // 少数点計算では誤差が発生するので丸める（The Amoebaがおそらく切り捨て）
                    console.log(`チームの総時間 : ${totalTime}`);
                    console.log(`各チームの時間 : ${teamTime}`);

                    let decimalPoint: number = kamoku[destKamoku].decimalPoint
                    let decimalPointPlace: number = 0
                    let place: number = 0
                    if ( decimalPoint === 1 ) {
                        decimalPointPlace = kamoku[destKamoku].decimalPointPlace
                        place = Math.pow(decimalPointPlace/decimalPointPlace*10, decimalPointPlace)
                    }

                    // STEP3.
                    /////////////////////////////////////  START   //////////////////////////////////////
                    // 
                    //  端数負担ロジック
                    // 
                    //  ※少数計算のポイントは下記
                    // 
                    //  1. チームの総時間は小数点第2位まで、以下切り捨て
                    //  2. 按分後の数値合計は、按分先科目の指定された小数点で四捨五入
                    //
                    //////////////////////////////////////////////////////////////////////////////////////
                    let fractionValue = 0;
                    let anbunRateTotal = 0;             // 按分比率合計の調整用
                    let effectiveDigit = 4;             // 有効桁数
                    let numberAdjustment = 10 ** effectiveDigit;
                    let anbunRateFractionList = []
                    let anbunRateFractionEffectiveDigit = 8;
                    let anbunRateFractionAdjustment = 10 ** anbunRateFractionEffectiveDigit;
                    let anbunRateFractionTotal = 0;

                    // 按分率と按分率合計値の計算
                    for ( let i = 0 ; i < teamTime.length; i++ ) {
                        let anbunRate = teamTime[i] / totalTime;
                        if ( isNaN(anbunRate) || anbunRate < 0 ) { anbunRate = 0 }                       // ゼロ割 or ゼロ以下なら0を返す
                        anbunRateList[i] = MathRoundCustom(anbunRate*numberAdjustment)/numberAdjustment;
                        anbunRateTotal = anbunRateTotal + anbunRateList[i]      // 按分比率の合計値を求める
                        anbunRateTotal = MathRoundCustom(anbunRateTotal*numberAdjustment)/numberAdjustment;
                    }
                    console.log(`按分比率 ${anbunRateList} : ${anbunRateList.length}`);
                    console.log(`__________ 按分比率の合計: ${anbunRateTotal} __________`)

                    // issue #128
                    // 按分率の合計値で比率調整
                    if ( 1 < anbunRateTotal ) {
                        totalTime = totalTime * anbunRateTotal
                        totalTime = MathRoundCustom(totalTime*numberAdjustment)/numberAdjustment;

                        // 按分率の再計算
                        let anbunRateTotalRe = 0        // 再計算後に発生する誤差調整用
                        for ( let j = 0; j < teamTime.length; j++ ) {
                            let anbunRate = teamTime[j] / totalTime
                            if ( isNaN(anbunRate) || anbunRate < 0 ) { anbunRate = 0 }                       // ゼロ割 or ゼロ以下なら0を返す
                            anbunRateList[j] = Math.trunc(anbunRate*numberAdjustment)/numberAdjustment;

                            anbunRateTotalRe = anbunRateTotalRe + anbunRateList[j]      // 按分比率の合計値を求める
                            anbunRateTotalRe = MathRoundCustom(anbunRateTotalRe*numberAdjustment)/numberAdjustment;
                        }

                        // 再計算後に発生した誤差は、端数負担先チームに負担
                        if ( anbunRateTotalRe < 1 || 1 < anbunRateTotalRe ) {
                            let rateAdjustment = 1 - anbunRateTotalRe
                            rateAdjustment = MathRoundCustom(rateAdjustment*numberAdjustment)/numberAdjustment;
                            for ( let j = 0; j < destTeamCode.length; j++ ) {
                                if ( j === fractionIndex ) {
                                    console.log(`***** 按分率再計算後の按分率合計：${anbunRateTotalRe}, 再計算後の按分率誤差：${rateAdjustment}, 負担先：${destTeamCode[j]} *****`)
                                    anbunRateList[j] = anbunRateList[j] + rateAdjustment
                                    anbunRateList[j] = MathRoundCustom(anbunRateList[j]*numberAdjustment)/numberAdjustment;
                                }
                            }
                        }
                        console.log(`***** 按分比率合計を掛けたチームの総時間合計：${totalTime}, 按分比率合計：${anbunRateTotal}  *****`)
                        totalTime = totalTime / anbunRateTotal      // 按分元の総時間に倍率が係ったままなので戻す
                        totalTime = MathRoundCustom(totalTime*numberAdjustment)/numberAdjustment;
                        console.log(`***** 按分比率合計で割り戻した総時間合計：${totalTime}  *****`)
                    } else {
                        // // ----- issue #225 START -----
                        // 按分率の合計値が1以下の場合に該当
                        // 按分率を小数点第5位以下を切り捨てる
                        // 切り捨てた端数の合計値を、端数負担チームの按分率に加算する
                        for ( let i = 0 ; i < teamTime.length; i++ ) {
                            let anbunRate = teamTime[i] / totalTime;
                            if ( isNaN(anbunRate) || anbunRate < 0 ) { anbunRate = 0 }                       // ゼロ割 or ゼロ以下なら0を返す
                            anbunRateList[i] = Math.trunc(anbunRate*numberAdjustment)/numberAdjustment;     // 小数点第5位以下切り捨て
                            anbunRateFractionList[i] = anbunRate - anbunRateList[i]                         // 按分率の小数点第5位以下の端数を求める
                            anbunRateFractionList[i] = Math.trunc(anbunRateFractionList[i]*anbunRateFractionAdjustment)/anbunRateFractionAdjustment;     // 小数点第8位以下を切り捨て
                            anbunRateFractionTotal += anbunRateFractionList[i];                 // 按分率の小数点第5位以下の端数合計を計算
                        }
                        console.log(`按分比率の端数リスト ${anbunRateFractionList} : ${anbunRateFractionList.length}`);
                        console.log(`__________ 按分比率の端数合計: ${anbunRateFractionTotal} __________`)

                        for ( let i = 0 ; i < destTeamCode.length; i++ ) {
                            if ( i === fractionIndex ) {
                                anbunRateList[i] += anbunRateFractionTotal
                                anbunRateList[i] = MathRoundCustom(anbunRateList[i]*numberAdjustment)/numberAdjustment
                            }
                        }
                        // // ----- issue #225 E N D -----
                    }

                    // 按分先の数値を計算
                    let anbunAfterTotal:number = 0;
                    for ( let i = 0 ; i < teamTime.length; i++ ) {
                        anbunAfterList[i] = (anbunSource * numberAdjustment) * (anbunRateList[i] * numberAdjustment) / (numberAdjustment ** 2);   // 剰余算の場合、「有効桁数のべき乗（項の数を乗数に）」で割り戻す
                        decimalPoint === 1
                        ? anbunAfterList[i] = Math.trunc(anbunAfterList[i]*place)/place     // 小数点以下を指定して切り捨て
                        : anbunAfterList[i] = Math.trunc(anbunAfterList[i]);                // 小数点以下はすべて切り捨て
                    }

                    // 按分する採算科目の数値と、按分先に振り分ける数値の合計値に差異がある場合、端数負担チームに端数を加算する
                    for ( let i = 0 ; i < destTeamCode.length; i++ ) {
                        if ( i === fractionIndex ) {                        // 端数を負担するチームかどうか
                            for ( let j = 0; j < anbunAfterList.length; j++ ) { anbunAfterTotal += anbunAfterList[j] }

                            if ( decimalPoint === 1 ) {
                                anbunAfterTotal     = MathRoundCustom(anbunAfterTotal*place)/place; // 小数計算で誤差が発生するので指定された小数点で丸める
                                fractionValue       = anbunSource - anbunAfterTotal;                // 按分される数値との端数を求める（各チームに按分される数値の合計を引く）
                                fractionValue       = MathRoundCustom(fractionValue*place)/place;   // 指定された小数点で丸める
                                anbunAfterList[i]   += fractionValue;                               // 負担チームに端数を加算する
                                anbunAfterList[i]   = MathRoundCustom(anbunAfterList[i]*place)/place;
                            } else {
                                anbunAfterTotal     = MathRoundCustom(anbunAfterTotal);             // 小数計算で誤差が発生するので指定された小数点で丸める
                                fractionValue       = anbunSource - anbunAfterTotal;                // 按分される数値との端数を求める（各チームに按分される数値の合計を引く）
                                fractionValue       = MathRoundCustom(fractionValue)                // 指定された小数点で丸める
                                anbunAfterList[i]   += fractionValue;
                                anbunAfterList[i]   = MathRoundCustom(anbunAfterList[i])
                            }
                            // console.log(fractionValue, anbunAfterList[i])
                        }
                    }
                    console.log(`按分結果の合計 ${anbunAfterTotal}`)
                    console.log(`按分元科目 ${sourceKamoku}`);
                    console.log(`按分先の科目 ${destKamoku}`);
                    console.log(`チームの時間 ${teamTime}`);
                    console.log(`ターゲットチーム ${destTeamCode}`);
                    console.log(`按分比率 ${anbunRateList} : ${anbunRateList.length}`);
                    console.log(`按分比率合計 ${anbunRateTotal}`)
                    console.log(`按分結果 ${anbunAfterList}`);

                    // STEP4.
                    for ( let i = 0 ; i < destTeamCode.length ; i++ ) {
                        destData[i][destKamoku].value += anbunAfterList[i];         // 20220325 要確認　全社共通経費が各部門に按分されない件の対応
                        let dataProp = await CreateProp(destData[i])
                        destData[i] = dataProp.anbundata
                    };
            
                    // STEP5. 修正版
                    /////////////////////////////////////  START   //////////////////////////////////////
                    // 
                    //  按分元採算科目をゼロにマイナスするロジック
                    // 
                    //////////////////////////////////////////////////////////////////////////////////////
                    let targetValueList:Array<number> = []
                    let sourceMinusKamoku = ['2270','2338','2280','4050','4060']    // 按分先の採算科目にあったら、按分元の同じ採算科目を－にする
                    if ( sourceMinusKamoku.includes(destKamoku) ) {
                        let newValue = sourceData[sourceKamoku].value !== 0 ? sourceData[sourceKamoku].value * -1 : sourceData[sourceKamoku].value
                        sourceData[destKamoku].value = newValue

                        // bugfix-#329 STAT
                        if ( isHigherCode ) {
                            // 上位組織の場合は、子組織の同一採算科目の数値を取得し配列に格納する
                            for ( let j = 0; j < relationCodeList.length; j++ ) {
                                let relSource = db.collection("amoebaList").doc(relationCodeList[j]).collection("Saisan").doc(docid).collection("Month").doc(month);

                                await transaction.get(relSource)
                                .then((document) => {
                                    let data:any = document.get('data');
                                    let value = data[sourceKamoku].value === undefined ? 0 : data[sourceKamoku].value
                                    targetValueList.push(value)

                                    console.log(`code:${relationCodeList[j]} kamoku:${sourceKamoku} value:${data[sourceKamoku].value}`)
                                })
                                .catch(error => { throw `採算表を取得できませんでした。 STEP5. 子組織の採算表作成` })
                            }
                        }
                        // bugfix-#329 E N D

                        // // bugfix-#277 START
                        let newAnbunSourceKamoku:string = sourceMinusKamoku[sourceMinusKamoku.indexOf(destKamoku)]  // 集計科目を按分元にすると科目集計時に不整合が発生する
                        anbunProp = CreateAnbunProp(
                            anbunSource,
                            sourceTeamCode,
                            newAnbunSourceKamoku,       // 按分元の採算科目を変更する
                            destTeamCode,
                            destKamoku,
                            anbunAfterList,
                            anbunProp,
                            relationCodeList,
                            targetValueList
                        )

                        let anbunResultLog = CreateAnbunLogs(
                            patternCode,
                            sourceTeamCode,
                            newAnbunSourceKamoku,       // 按分元の採算科目を変更する
                            anbunSource,
                            destTeamCode,
                            destKamoku,
                            anbunAfterList,
                            anbunRateList,
                            teamTime,
                            totalTime,
                            false
                        )
                        anbunLogPropOutputs.push(anbunResultLog)
                        // // bugfix-#277 E N D
                    } else {
                        sourceData[destKamoku].value = 0;

                        // bugfix-#329 STAT
                        if ( isHigherCode ) {
                            // 上位組織の場合は、子組織の同一採算科目の数値を取得し配列に格納する
                            for ( let j = 0; j < relationCodeList.length; j++ ) {
                                let relSource = db.collection("amoebaList").doc(relationCodeList[j]).collection("Saisan").doc(docid).collection("Month").doc(month);

                                await transaction.get(relSource)
                                .then((document) => {
                                    let data:any = document.get('data');
                                    let value = data[sourceKamoku].value === undefined ? 0 : data[sourceKamoku].value
                                    targetValueList.push(value)

                                    console.log(`code:${relationCodeList[j]} kamoku:${sourceKamoku} value:${data[sourceKamoku].value}`)
                                })
                                .catch(error => { throw `採算表を取得できませんでした。 STEP5. 子組織の採算表作成` })
                            }
                        }
                        // bugfix-#329 E N D

                        // // bugfix-#277 START
                        anbunProp = CreateAnbunProp(
                            anbunSource,
                            sourceTeamCode,
                            sourceKamoku,
                            destTeamCode,
                            destKamoku,
                            anbunAfterList,
                            anbunProp,
                            relationCodeList,
                            targetValueList
                        )

                        let anbunResultLog = CreateAnbunLogs(
                            patternCode,
                            sourceTeamCode,
                            sourceKamoku,
                            anbunSource,
                            destTeamCode,
                            destKamoku,
                            anbunAfterList,
                            anbunRateList,
                            teamTime,
                            totalTime,
                            false
                        )
                        anbunLogPropOutputs.push(anbunResultLog)
                        // // bugfix-#277 E N D
                    }

                    let dataProp = await CreateProp(sourceData)
                    sourceData = dataProp.anbundata

                    // STEP6
                    /////////////////////////////////////  START   //////////////////////////////////////
                    // 
                    //  按分元チームが上位組織だった場合のロジック
                    //  - 按分元チームの採算表の数値で按分される
                    //  - 下位組織チームの採算表の按分元採算科目をゼロにするため
                    //  
                    //  !!!!! 下位組織の採算表を順番に按分していくロジックではない !!!!!
                    //  !!!!! 無理やりゼロにしてる !!!!!
                    // 
                    //////////////////////////////////////////////////////////////////////////////////////
                    if ( isHigherCode ) {
                        for ( let f = 0; f < relationCodeList.length; f++ ) {
                            if ( teamcodeListForUpdate.includes(relationCodeList[f]) ) {
                                let relCodeIdx:number = teamcodeListForUpdate.indexOf(relationCodeList[f])
                                let data:any = dataListForUpdate[relCodeIdx]

                                if ( sourceMinusKamoku.includes(destKamoku) ) {
                                    if ( data[sourceKamoku].value !== 0 ) {
                                        data[destKamoku].value = data[sourceKamoku].value * -1;
                                    }
                                } else {
                                    data[destKamoku].value = 0;
                                }

                                let dataProp = await CreateProp(data)
                                data = dataProp.anbundata
                                dataListForUpdate[relCodeIdx] = data
                                continue;
                            }
                            // 下位組織のチームコードがアップデータリストに入ってないなら、採算表を取得する
                            let relSource = db.collection("amoebaList").doc(relationCodeList[f]).collection("Saisan").doc(docid).collection("Month").doc(month);
                            let initData:any = {};

                            await transaction.get(relSource)
                            .then((document) => {
                                let data:any = document.get('data');
                                Object.keys(kamoku).map((code:string) => {
                                    if ( code === '9999' ) {
                                        initData = { ...initData, [code] : {'value' : 100}}
                                    } else {
                                        initData = { ...initData, [code] : {'value' : 0}}
                                    }
                                })
                                Object.keys(data).map(code => {
                                    if ( code === '9999' ) return
                                    initData[code].value += data[code].value
                                })
                            })
                            .then(() => {
                                if ( sourceMinusKamoku.includes(destKamoku) ) {
                                    if ( initData[sourceKamoku].value === 0 ) return
                                    initData[destKamoku].value = initData[sourceKamoku].value * -1;
                                    console.log(initData[destKamoku].value)
                                } else {
                                    initData[destKamoku].value = 0;
                                }
                            })
                            .catch(error => { throw `採算表を取得できませんでした。 STEP6. 按分パターン:${patternCode} ${relationCodeList[f]}` })

                            let dataProp = await CreateProp(initData)
                            initData = dataProp.anbundata
                            relCodeList.push(relationCodeList[f])
                            relDataList.push(initData)
                        }
                    }
                    /////////////////////////////////////  E N D   //////////////////////////////////////
                })
                .then(() => { console.log(`transaction success ${patternCode}`) })
                .catch(error => { throw error })
            }

            // STEP7
            let updateTeamList: Array<string> = [];
            let updateDataList: any = [];
            updateTeamList.push(...destTeamCode, ...relCodeList, sourceTeamCode);                       // 按分先と按分元のチームコード配列
            updateDataList.push(...destData, ...relDataList, sourceData)                                // 按分元の按分後のデータ（sourceData）も配列に格納してupdate関数に渡す

            for ( let i = 0; i < updateTeamList.length; i++ ) {                         // 按分パターンに登場するチームコードをすべて確認
                if ( teamcodeListForUpdate.includes(updateTeamList[i]) ) {
                    let idx:number = teamcodeListForUpdate.indexOf(updateTeamList[i])   // DB更新用配列のインデックスを取得
                    dataListForUpdate[idx] = updateDataList[i]                      // DB更新用データ保持配列の内容を置き換える
                } else {
                    teamcodeListForUpdate.push(updateTeamList[i]);
                    dataListForUpdate.push(updateDataList[i]);
                }
            }

            dispatch({ type: "PROGRESSING_PROCESS", name:`${year}年_${month}月 ${syurui}`, key:taskId })
        }
    }
    console.log(' -- 一旦確認 -- ')
    console.log(teamcodeListForUpdate)
    console.log(dataListForUpdate)
    console.log(anbunProp)
    console.log(' -- 一旦確認 -- ')

    // // 集計処理
    let initFormat:any      = kamoku;
    let leafCode:any        = [];       // 下位組織を持たない末端のチームコード
    let leafData:any        = {};
    let nodeCode:any        = [];       // 下位組織を持つチームコード
    let nodeData:any        = {};
    let relationList:any    = [];       // 下位組織チームコード格納用
    let relationObj:any     = {};       // 下位組織データ格納用

    //////////////////////////////////////////////////////////////////////////////////////
    // 
    //  下位組織のチーム、親組織のチーム、親組織が持つ下位組織チーム毎で配列に分ける
    // 
    //////////////////////////////////////////////////////////////////////////////////////
    Object.keys(teams).map((team:string) => {
        if ( teams[team].relationCode === undefined ) {      // 最下層のチームコード
            leafCode.push(team)
        } else {        // 上位組織のチームコード
            let nodeExist:boolean = nodeCode.some((element:string) => element === team )
            if ( !nodeExist ) { nodeCode.push(team) }

            let codeExist:boolean = relationList.some((element:string) => element === team )
            if ( !codeExist ) {
                relationList.push(teams[team].relationCode)
                relationObj = { ...relationObj, [team] : teams[team].relationCode }
            }
        }
    })

    //////////////////////////////////////////////////////////////////////////////////////
    // 
    //  親組織チームの採算表を新規で作成
    // 
    //////////////////////////////////////////////////////////////////////////////////////
    for ( let i = 0; i < nodeCode.length; i++ ) {
        let data:any = {};
        Object.keys(initFormat).map((code:string) => {
            code === '9999'
            ? data = { ...data, [code] : {'value' : 100}}
            : data = { ...data, [code] : {'value' : 0}}
        })
        data = { [nodeCode[i]] : data }
        nodeData = { ...nodeData, ...data }
    }

    //////////////////////////////////////////////////////////////////////////////////////
    // 
    //  下位組織チームの採算表を新規で作成
    // 
    //////////////////////////////////////////////////////////////////////////////////////
    for ( let i = 0; i < leafCode.length; i++ ) {
        let data:any = {};
        let tempcodeIdx:number = 0;
        tempcodeIdx = teamcodeListForUpdate.indexOf(leafCode[i]);
        if ( tempcodeIdx >= 0 ) {     // 採算表のデータを持ってたら
            data = { [leafCode[i]] : { ...dataListForUpdate[tempcodeIdx] } }
        }

        leafData = { ...leafData, ...data };
    }
    console.log(nodeData)
    console.log(leafData)

    //////////////////////////////////////////////////////////////////////////////////////
    // 
    //  集計用ロジック
    //
    //  - A1000が按分元になるので、A1000が来たときは下位組織チームコードからの集計を行わない
    //  - 親組織への集計時に少数計算が発生するので、整数に直して計算後元の桁数に戻す
    // 
    //////////////////////////////////////////////////////////////////////////////////////
    const RecursiveTotal = async (parentCode:string) => {
        console.log('A RecursiveTotal function was called.')
        for ( let i = 0; i < relationObj[parentCode].length; i++ ) {
            const code = relationObj[parentCode][i]
            let codeExist:boolean = nodeCode.some((element:string) => element === code )    // A1000系なのかA101系なのかのチェック（下位組織を持つかどうか）
            if ( codeExist ) {      // codeが上位組織の場合
                if ( code === 'A1000' ) {
                    let tempcodeIdx:number = teamcodeListForUpdate.indexOf('A1000');
                    nodeData[code] = { ...dataListForUpdate[tempcodeIdx] }
                    Object.keys(nodeData[parentCode]).map((kamoku:string) => {
                        if ( Number(kamoku) > 9998 ) return
                        if ( initFormat[kamoku].decimalPoint > 0 ) {                    // 小数点があるなら
                            let decToNum:number = 10 ** initFormat[kamoku].decimalPointPlace        // 小数桁数をべき乗
                            nodeData[parentCode][kamoku].value = MathRoundCustom((nodeData[parentCode][kamoku].value * decToNum)) + MathRoundCustom((nodeData[code][kamoku].value * decToNum))
                            nodeData[parentCode][kamoku].value = nodeData[parentCode][kamoku].value / decToNum
                        } else {
                            nodeData[parentCode][kamoku].value += nodeData[code][kamoku].value      // 上位組織の採算科目に足し算していく
                        }
                    })
                } else {
                    let returnData:any = await RecursiveTotal(code);              // まだ下位組織を持っている場合再帰させる
                    Object.keys(nodeData[parentCode]).map((kamoku:string) => {
                        if ( Number(kamoku) > 9998 ) return
                        if ( initFormat[kamoku].decimalPoint > 0 ) {                    // 小数点があるなら
                            let decToNum:number = 10 ** initFormat[kamoku].decimalPointPlace        // 小数桁数をべき乗
                            nodeData[parentCode][kamoku].value = MathRoundCustom((nodeData[parentCode][kamoku].value * decToNum)) + MathRoundCustom((returnData[kamoku].value  * decToNum))   // 小数計算の誤差が発生してしますので四捨五入
                            nodeData[parentCode][kamoku].value = nodeData[parentCode][kamoku].value / decToNum
                        } else {
                            nodeData[parentCode][kamoku].value += returnData[kamoku].value      // 上位組織の採算科目に足し算していく
                        }
                    })
                }
            } else {
                // codeが下位組織の場合
                Object.keys(nodeData[parentCode]).map((kamoku:string) => {
                    if ( Number(kamoku) > 9998 ) return
                    if ( initFormat[kamoku].decimalPoint > 0 ) {                    // 小数点があるなら
                        let decToNum:number = 10 ** initFormat[kamoku].decimalPointPlace        // 小数桁数をべき乗
                        nodeData[parentCode][kamoku].value = MathRoundCustom(nodeData[parentCode][kamoku].value * decToNum) + MathRoundCustom(leafData[code][kamoku].value * decToNum)
                        nodeData[parentCode][kamoku].value = nodeData[parentCode][kamoku].value / decToNum
                    } else {
                        nodeData[parentCode][kamoku].value += leafData[code][kamoku].value      // 上位組織の採算科目に足し算していく
                    }
                })
            }
        }

        nodeData[parentCode] = await TotalForKamoku(nodeData[parentCode], "recursive")
        dispatch({ type: "PROGRESSING_PROCESS", name:`${year}年_${month}月 ${syurui}`, key:taskId })
        return nodeData[parentCode]      // 上位組織の集計後の数値をかえす
    }

    // 全社へ集計させる
    nodeData['0000'] = await RecursiveTotal('0000')
    console.log(nodeData)
    console.log(leafData)

    // 更新
    let today = new Date();
    await db.runTransaction(async transaction => {
        Object.keys(leafData).map((code:string) => {
            const targetDoc = db.collection("amoebaList").doc(code).collection("Saisan").doc(docid).collection("Month").doc(month);
            let dataProp:any = { data : leafData[code], date : today }
            transaction.update(targetDoc, dataProp);

            // bugfix-#277 START
            if ( anbunProp[code] !== undefined ) {
                let data = anbunProp[code]          // 該当するチームコードの配賦される科目と数値を取得
                let updateProp = {
                    "anbun": [
                        {
                            "data": data,
                            "id": transactionID,
                        }
                    ]
                }
                transaction.update(targetDoc, updateProp)
            }
            // bugfix-#277 E N D

            // 按分済みのステータスを年月ドキュメントに書き込み
            if ( syurui === "MP" ) {
                const anbunStateDoc = db.collection("amoebaList").doc(code).collection("Saisan").doc(docid);
                transaction.update(anbunStateDoc, { anbunState : true })
            } else {
                const anbunStateDoc = db.collection("amoebaList").doc(code).collection("Saisan").doc(docid).collection("Month").doc(month);
                transaction.update(anbunStateDoc, { anbunState : true })
            }

            dispatch({ type: "PROGRESSING_PROCESS", name:`${year}年_${month}月 ${syurui}`, key:taskId })
        })
        Object.keys(nodeData).map((code:string) => {
            const targetDoc = db.collection("amoebaList").doc(code).collection("Saisan").doc(docid).collection("Month").doc(month);
            let dataProp:any = { data : nodeData[code], date : today }
            transaction.update(targetDoc, dataProp);

            // bugfix-#277 START
            if ( anbunProp[code] !== undefined ) {
                let data = anbunProp[code]          // 該当するチームコードの配賦される科目と数値を取得
                let updateProp = {
                    "anbun": [
                        {
                            "data": data,
                            "id": transactionID,
                        }
                    ]
                }
                transaction.update(targetDoc, updateProp)
            }
            // bugfix-#277 E N D

            // 按分済みのステータスを年月ドキュメントに書き込み
            if ( syurui === "MP" ) {
                const anbunStateDoc = db.collection("amoebaList").doc(code).collection("Saisan").doc(docid);
                transaction.update(anbunStateDoc, { anbunState : true })
            } else {
                const anbunStateDoc = db.collection("amoebaList").doc(code).collection("Saisan").doc(docid).collection("Month").doc(month);
                transaction.update(anbunStateDoc, { anbunState : true })
            }

            dispatch({ type: "PROGRESSING_PROCESS", name:`${year}年_${month}月 ${syurui}`, key:taskId })
        })
    })
    .then(() => {
        UpdateRelationTeam(nodeData, leafData, docid, month)
    })
    .then(() => {
        console.log('This transaction was successed.')
        // bugfix-#277 START
        CreateAnbunLog(anbunLogPropOutputs, syurui, year, month, transactionID)
        .then(() => console.log(`Log write successful.`))
        .catch((e) => { throw `ログの書き込みに失敗しました。` })
        // bugfix-#277 E N D
    })
    .catch(() => { throw `データベースの更新に失敗しました。` })

    console.log('for end.')
    return transactionID
    /////////////////////////////////////  E N D   //////////////////////////////////////
    // 
    // 1. 按分 (fractionTeamCodeは必ず設定されてる想定)
    //
    //////////////////////////////////////////////////////////////////////////////////////
}

async function CreateProp (sourceData:Object) {
    let today = new Date();
    let data = await TotalForKamoku(sourceData, "distribution")
    let dataProp:any = { anbundata: data, date: today };

    return dataProp;
}

// bugfix-#277 START
function CreateAnbunProp (
    sourceData:number,
    sourceTeam:string,
    sourceKamoku:string,
    destinationTeams:Array<string>,
    destinationKamoku:string,
    values:Array<number>|number,
    props:any,
    childrenCodes?:Array<any>,
    childrenValues?:Array<number>
) {
    // 固定振替の場合は引数の数値を配列型に持ち替える
    if ( typeof values === "number" ) {
        let newValues:Array<number> = []
        newValues.push(values)
        values = newValues
    }
    const teamKeys = Object.keys(props)     // チームコードの一覧

    // 按分元科目の処理
    // bugfix-#329 START
    // 下位組織の処理　上位組織の場合は、所属する下位組織の数値をチーム毎に値を保持する
    if ( childrenCodes !== undefined && childrenValues !== undefined ) {
        console.log(`children props is undefined.`)
        if ( childrenCodes.length > 0 || childrenValues.length > 0 ) {
            console.log(`children props is more than 1.`)
            for ( let i = 0; i < childrenCodes.length; i++ ) {
                if ( teamKeys.includes(childrenCodes[i]) ) {      // 既に採算科目が連想配列状に保持されている場合は、加算する
                    const targetTeam = childrenCodes[i]
                    const kamokuKeys = Object.keys(props[targetTeam])   // 該当するチームの採算科目の一覧を取得
            
                    if ( kamokuKeys.includes(sourceKamoku) ) {
                        let targetKamokuProp = props[targetTeam]        //　更新データのみの採算表
                        let newValue = childrenValues[i] === 0 ? childrenValues[i] : childrenValues[i] * -1        // 配賦される値を初期値にセット
                        newValue += props[targetTeam][sourceKamoku].value         // 同一の科目で既に値を保持している場合は加算
                        targetKamokuProp = {
                            ...targetKamokuProp,
                            [sourceKamoku]: {
                                value: newValue
                            }
                        }
                        props = {
                            ...props,
                            [targetTeam]: {
                                ...targetKamokuProp,
                            }
                        }
                    } else {
                        let targetKamokuProp = props[targetTeam]        //　更新データのみの採算表
                        let newValue = childrenValues[i] === 0 ? childrenValues[i] : childrenValues[i] * -1        // 配賦される値を初期値にセット
                        targetKamokuProp = {
                            ...targetKamokuProp,
                            [sourceKamoku]: {
                                value: newValue,
                            }
                        }
                        props = {
                            ...props,
                            [targetTeam]: {
                                ...targetKamokuProp,
                            }
                        }
                    }
                } else {
                    const targetTeam = childrenCodes[i]
                    const newValue = childrenValues[i] === 0 ? childrenValues[i] : childrenValues[i] * -1
                    props = {
                        ...props,
                        [targetTeam]: {
                            [sourceKamoku]: {
                                value: newValue,
                            }
                        }
                    }
                }
            }
        }
    }
    // bugfix-#329 E N D
    
    if ( teamKeys.includes(sourceTeam) ) {      // 既に採算科目が連想配列状に保持されている場合は、加算する
        const targetTeam = sourceTeam
        const kamokuKeys = Object.keys(props[targetTeam])   // 該当するチームの採算科目の一覧を取得

        if ( kamokuKeys.includes(sourceKamoku) ) {
            let targetKamokuProp = props[targetTeam]        //　更新データのみの採算表
            // let newValue = sourceData        // 配賦される値を初期値にセット
            let newValue = sourceData === 0 ? sourceData : sourceData * -1        // 配賦される値を初期値にセット
            newValue += props[targetTeam][sourceKamoku].value         // 同一の科目で既に値を保持している場合は加算
            targetKamokuProp = {
                ...targetKamokuProp,
                [sourceKamoku]: {
                    value: newValue
                }
            }
            props = {
                ...props,
                [targetTeam]: {
                    ...targetKamokuProp,
                }
            }
        } else {
            let targetKamokuProp = props[targetTeam]        //　更新データのみの採算表
            let newValue = sourceData === 0 ? sourceData : sourceData * -1        // 配賦される値を初期値にセット
            targetKamokuProp = {
                ...targetKamokuProp,
                [sourceKamoku]: {
                    value: newValue,
                }
            }
            props = {
                ...props,
                [targetTeam]: {
                    ...targetKamokuProp,
                }
            }
        }
    } else {
        const targetTeam = sourceTeam
        const newValue = sourceData === 0 ? sourceData : sourceData * -1
        props = {
            ...props,
            [targetTeam]: {
                [sourceKamoku]: {
                    value: newValue,
                }
            }
        }
    }

    // 按分先科目の処理
    for ( let i = 0 ; i < destinationTeams.length ; i++ ) {
        const targetTeam = destinationTeams[i]

        if ( teamKeys.includes(targetTeam) ) {
            const kamokuKeys = Object.keys(props[targetTeam])   // 該当するチームの採算科目の一覧を取得

            if ( kamokuKeys.includes(destinationKamoku) ) {
                // 按分先の科目があるなら加算する
                let targetKamokuProp = props[targetTeam]        //　更新データのみの採算表
                let newValue = values[i]        // 配賦される値を初期値にセット
                newValue += props[targetTeam][destinationKamoku].value         // 同一の科目で既に値を保持している場合は加算
                targetKamokuProp = {
                    ...targetKamokuProp,
                    [destinationKamoku]: {
                        value: newValue
                    }
                }
                props = {
                    ...props,
                    [targetTeam]: {
                        ...targetKamokuProp,
                    }
                }
            } else {
                let targetKamokuProp = props[targetTeam]        //　更新データのみの採算表
                targetKamokuProp = {
                    ...targetKamokuProp,
                    [destinationKamoku]: {
                        value: values[i],
                    }
                }
                props = {
                    ...props,
                    [targetTeam]: {
                        ...targetKamokuProp,
                    }
                }
            }
        } else {
            const targetTeam = destinationTeams[i]
            props = {
                ...props,
                [targetTeam]: {
                    [destinationKamoku]: {
                        value: values[i]
                    }
                }
            }
        }
    };

    return props
}

function CreateAnbunLogs (
    patternCode:string,
    sourceTeam:string,
    sourceKamoku:string,
    sourceValue:number,
    destinationTeams:Array<string>,
    destinationKamoku:string,
    destinationValues:Array<number>|number,
    destinationRate:Array<number>|number,
    destinationTimes:Array<number>|"fixed",
    totalTime:number|"fixed",
    isFixed:boolean
) {
    if ( isFixed ) {
        let prop = {
            "anbun": [
                {
                    "category": "rate",
                    "values": destinationRate,
                },
                {
                    "category": "value",
                    "values": destinationValues,
                },
            ],
            "kamoku_destination": destinationKamoku,
            "kamoku_source": sourceKamoku,
            "patternCode": patternCode,
            "teams_destination": destinationTeams,
            "team_source": sourceTeam,
            "value_source": sourceValue,
        }
        return prop
    }
    let prop = {
        "anbun": [
            {
                "category": "rate",
                "values": destinationRate,
            },
            {
                "category": "value",
                "values": destinationValues,
            },
            {
                "category": "time",
                "values": destinationTimes,
            },
        ],
        "kamoku_destination": destinationKamoku,
        "kamoku_source": sourceKamoku,
        "patternCode": patternCode,
        "teams_destination": destinationTeams,
        "team_source": sourceTeam,
        "value_source": sourceValue,
        "total_time": totalTime,
    }
    return prop
}

function KeyGenerate () {
    const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
    let key = ""

    for( let i = 0; i < 20; i++ ) {
        while( true ) {
            const random = chars.charAt(Math.floor(Math.random() * chars.length));
            key += random;
            break;
        }
    }

    return key;
}

async function UpdateRelationTeam (nodeData:any, leafData:any, docid:string, month:string) {
    const teams = await ReadTeams()
    const nodeTeams = Object.keys(nodeData)

    await db.runTransaction(async transaction => {
        nodeTeams.map((teamcode) => {
            const relationTeams = teams[teamcode].relationCode
            if ( relationTeams === undefined ) return
            for ( let i = 0; i < relationTeams.length; i++ ) {
                let target = db.collection("amoebaList").doc(teamcode).collection('Child').doc(relationTeams[i]).collection('Saisan').doc(docid).collection('Month').doc(month)
                if ( nodeTeams.includes(relationTeams[i]) ) {
                    transaction.update(target, { "data": nodeData[relationTeams[i]] })
                    continue;
                }
                transaction.update(target, { "data": leafData[relationTeams[i]] })
            }
        })
    })
    .catch((e) => { throw(`中間領域の更新失敗`) })
}
// bugfix-#277 E N D

async function revertToDistribute (month:string, year:string, syurui:string, dispatch:React.Dispatch<ACTION>, taskId:string) {
    let teams:any = await ReadTeams();
    let kamoku = await ReadKamoku()
    let initFormat:any = kamoku;
    let docid: string = year + syurui;

    let maxCount:number = Object.keys(teams).length;
    dispatch({ type:"WAIT_FUNCTIONS", maxCount:maxCount+1, status:"loading", key:taskId });

    // bugfix-#277 START
    //////////////////////////////////////////////////////////////////////////////////////
    // 
    //  配賦された値を各チームの採算表に加算
    // 
    //////////////////////////////////////////////////////////////////////////////////////
    // 該当する採算表の最新の按分結果IDを取得する
    const anbunLogProp = await ReadAnbunLog(syurui, year, month)        // { outputs:outputs, id:id }
    const anbunLatestID = anbunLogProp.id       // 按分の最新実行結果ID

    // bugfix-#314 START
    if ( anbunLatestID === "" ) {
        // 0000のanbundataが存在すれば他のチームも存在すると想定
        const target = db.collection("amoebaList").doc("0000").collection("Saisan").doc(year+syurui).collection("Month").doc(month)
        const result = await target.get()
        .then((doc) => {
            const value = doc.get('anbundata')
            return value
        })

        // anbundataが存在しなければ、正常なパターンなので処理終了
        if ( result === undefined ) throw `按分結果が存在しませんでした。`

        // anbundataが存在する場合
        await ForOldRevertDistribution(month, year, syurui, dispatch, taskId)
        .then(() => { dispatch({ type: "PROGRESSING_PROCESS", name:`${year}年_${month}月 ${syurui}`, key:taskId }) })
        .catch(error => { throw `旧按分解除の処理に失敗しました。` })

        return
    }
    // bugfix-#314 E N D

    let leafCode:any        = [];       // 下位組織を持たない末端のチームコード
    let leafData:any        = {};
    let nodeCode:any        = [];       // 下位組織を持つチームコード
    let nodeData:any        = {};
    let relationList:any    = [];       // 下位組織チームコード格納用
    let relationObj:any     = {};       // 下位組織データ格納用
    let teamLists:Array<string> = []

    await db.collection("amoebaList").get()
    .then((querySnapshot) => {
        querySnapshot.forEach((codeDoc) => {
            teamLists.push(codeDoc.id)
        })
    })
    .catch(() => { throw `チームコードの取得に失敗しました。` })

    //////////////////////////////////////////////////////////////////////////////////////
    // 
    //  下位組織のチーム、親組織のチーム、親組織が持つ下位組織チーム毎で配列に分ける
    //  - 集計関数用のチームリストを作成する
    // 
    //////////////////////////////////////////////////////////////////////////////////////
    Object.keys(teams).map((team:string) => {
        if ( teams[team].relationCode === undefined ) {      // 最下層のチームコード
            leafCode.push(team)
        } else {        // 上位組織のチームコード
            let nodeExist:boolean = nodeCode.some((element:string) => element === team )
            if ( !nodeExist ) { nodeCode.push(team) }

            let codeExist:boolean = relationList.some((element:string) => element === team )
            if ( !codeExist ) {
                relationList.push(teams[team].relationCode)
                relationObj = { ...relationObj, [team] : teams[team].relationCode }
            }
        }
    })

    // 上位組織の採算表を作る
    for ( let i = 0; i < nodeCode.length; i++ ) {
        let data:any = {};
        Object.keys(initFormat).map((code:string) => {
            code === '9999'
            ? data = { ...data, [code] : {'value' : 100}}
            : data = { ...data, [code] : {'value' : 0}}
        })
        data = { [nodeCode[i]] : data }
        nodeData = { ...nodeData, ...data }
    }

    // チームコードの数だけ回す
    try {
        console.log(`try`)
        for ( let i = 0; i < teamLists.length; i++ ) {
            let teamcode = teamLists[i]
            let target = db.collection("amoebaList").doc(teamcode).collection("Saisan").doc(docid).collection('Month').doc(month);
            let source:any = {}
            let anbunProp:Array<any> = []
    
            //////////////////////////////////////////////////////////////////////////////////////
            //
            // 採算表から按分後の数値と配賦されている値を取得
            // 
            // - 読み込んだ採算表のデータを下位組織と上位組織にわけて保持する
            // - anbunフィールドを持っている場合は、配賦された値を減算した後に下位組織と上位組織にわける
            // 
            //////////////////////////////////////////////////////////////////////////////////////
            await target.get()
            .then((doc) => {
                let saisan = doc.data()
                if ( saisan === undefined ) return
                source = saisan["data"]
                anbunProp = saisan["anbun"]         // [{ data: { 1300: { value: 1000 }, { value: 100 } }, id:<乱数> }, { data: { ... } } ]
    
                // anbunプロパティを持っていない場合
                if ( anbunProp === undefined ) {
                    console.log(`undefined`)
                    // 上位組織でもA1000の場合は、採算表は取得
                    if ( teamcode === "A1000" ) {
                        nodeData = { ...nodeData, [teamcode]: saisan["data"] }
                        return
                    }
                    // 上位組織の採算表は取得しない
                    if ( nodeCode.includes(teamcode) ) return
                    // 下位組織に含まれていたら
                    leafData = { ...leafData, [teamcode]: saisan["data"] }
                }
            })
            .catch((e) => console.log(`ドキュメント取得エラー ${e}`))
    
            if ( anbunProp === undefined ) continue         // anbunフィールドを持っていない場合は、配賦値の減算処理は不要なのでスキップ
    
            //////////////////////////////////////////////////////////////////////////////////////
            //
            // 採算表を取得できたら配賦された値をマイナスする
            //
            //////////////////////////////////////////////////////////////////////////////////////
            let isUpdate = false
            anbunProp.map((anbunDataset:any) => {
                let anbunDatasetID = anbunDataset.id
    
                // 按分整合性担保 按分の結果IDが異なる場合は処理しない（1回だけHITするか、まったくHITしないか）
                // 同一の按分結果IDが複数存在することは想定外
                if ( anbunLatestID !== anbunDatasetID ) return
    
                isUpdate = true
                let anbunDatasetAllocateList = anbunDataset.data
                Object.keys(anbunDatasetAllocateList).map((allocateKamoku:string) => {
                    source[allocateKamoku].value += (anbunDatasetAllocateList[allocateKamoku].value * -1)
                })
            })
    
            if ( !isUpdate ) continue      // anbunプロパティ内に同一の按分結果IDが一つも存在しない場合は、配賦値の減算処理は不要なのでスキップ
    
            source = await TotalForKamoku(source, "distribution")
    
            // 上位組織か下位組織に分ける
            if ( nodeCode.includes(teamcode) ) {
                nodeData = { ...nodeData, [teamcode]: source }
            }
            if ( leafCode.includes(teamcode) ) {
                leafData = { ...leafData, [teamcode]: source }
            }
    
            console.log('[ ' + teamcode + ' ] success.')
        }
    } catch(e) {
        console.log(`catch`)
        throw `減算処理に失敗しました。`
    }

    // 再帰的に集計していく
    const RecursiveTotal = async (parentCode:string) => {
        console.log('A RecursiveTotal function was called.')
        for ( let i = 0; i < relationObj[parentCode].length; i++ ) {
            const code = relationObj[parentCode][i]
            let codeExist:boolean = nodeCode.some((element:string) => element === code )    // A1000系なのかA101系なのかのチェック（下位組織を持つかどうか）
            if ( codeExist ) {      // codeが上位組織の場合
                if ( code === 'A1000' ) {
                    Object.keys(nodeData[parentCode]).map((kamoku:string) => {
                        if ( Number(kamoku) > 9998 ) return
                        if ( initFormat[kamoku].decimalPoint > 0 ) {                    // 小数点があるなら
                            let decToNum:number = 10 ** initFormat[kamoku].decimalPointPlace        // 小数桁数をべき乗
                            nodeData[parentCode][kamoku].value = MathRoundCustom((nodeData[parentCode][kamoku].value * decToNum)) + MathRoundCustom((nodeData[code][kamoku].value * decToNum))
                            nodeData[parentCode][kamoku].value = nodeData[parentCode][kamoku].value / decToNum
                        } else {
                            nodeData[parentCode][kamoku].value += nodeData[code][kamoku].value      // 上位組織の採算科目に足し算していく
                        }
                    })
                } else {
                    let returnData:any = await RecursiveTotal(code);              // まだ下位組織を持っている場合再帰させる
                    Object.keys(nodeData[parentCode]).map((kamoku:string) => {
                        if ( Number(kamoku) > 9998 ) return
                        if ( initFormat[kamoku].decimalPoint > 0 ) {                    // 小数点があるなら
                            let decToNum:number = 10 ** initFormat[kamoku].decimalPointPlace        // 小数桁数をべき乗
                            nodeData[parentCode][kamoku].value = MathRoundCustom((nodeData[parentCode][kamoku].value * decToNum)) + MathRoundCustom((returnData[kamoku].value  * decToNum))   // 小数計算の誤差が発生してしますので四捨五入
                            nodeData[parentCode][kamoku].value = nodeData[parentCode][kamoku].value / decToNum
                        } else {
                            nodeData[parentCode][kamoku].value += returnData[kamoku].value      // 上位組織の採算科目に足し算していく
                        }
                    })
                }
            } else {
                // codeが下位組織の場合
                Object.keys(nodeData[parentCode]).map((kamoku:string) => {
                    if ( Number(kamoku) > 9998 ) return
                    if ( initFormat[kamoku].decimalPoint > 0 ) {                    // 小数点があるなら
                        let decToNum:number = 10 ** initFormat[kamoku].decimalPointPlace        // 小数桁数をべき乗
                        nodeData[parentCode][kamoku].value = MathRoundCustom(nodeData[parentCode][kamoku].value * decToNum) + MathRoundCustom(leafData[code][kamoku].value * decToNum)
                        nodeData[parentCode][kamoku].value = nodeData[parentCode][kamoku].value / decToNum
                    } else {
                        nodeData[parentCode][kamoku].value += leafData[code][kamoku].value      // 上位組織の採算科目に足し算していく
                    }
                })
            }
        }

        nodeData[parentCode] = await TotalForKamoku(nodeData[parentCode], "recursive")
        dispatch({ type: "PROGRESSING_PROCESS", name:`${year}年_${month}月 ${syurui}`, key:taskId })
        return nodeData[parentCode]      // 上位組織の集計後の数値をかえす
    }
    nodeData['0000'] = await RecursiveTotal('0000')
    console.log(nodeData)
    console.log(leafData)

    // 按分ログの削除
    await db.runTransaction(async transaction => {
        Object.keys(leafData).map((code:string) => {
            const targetDoc = db.collection("amoebaList").doc(code).collection("Saisan").doc(docid).collection("Month").doc(month);
            let dataProp:any = { data : leafData[code], }
            transaction.update(targetDoc, dataProp);        // DB更新場所

            if ( syurui === "MP" ) {
                const anbunStateDoc = db.collection("amoebaList").doc(code).collection("Saisan").doc(docid);
                transaction.update(anbunStateDoc, { anbunState : false })
            } else {
                const anbunStateDoc = db.collection("amoebaList").doc(code).collection("Saisan").doc(docid).collection("Month").doc(month);
                transaction.update(anbunStateDoc, { anbunState : false })
            }

            dispatch({ type: "PROGRESSING_PROCESS", name:`${year}年_${month}月 ${syurui}`, key:taskId })
        })
        Object.keys(nodeData).map((code:string) => {
            const targetDoc = db.collection("amoebaList").doc(code).collection("Saisan").doc(docid).collection("Month").doc(month);
            let dataProp:any = { data : nodeData[code], }
            transaction.update(targetDoc, dataProp);        // DB更新場所

            if ( syurui === "MP" ) {
                const anbunStateDoc = db.collection("amoebaList").doc(code).collection("Saisan").doc(docid);
                transaction.update(anbunStateDoc, { anbunState : false })
            } else {
                const anbunStateDoc = db.collection("amoebaList").doc(code).collection("Saisan").doc(docid).collection("Month").doc(month);
                transaction.update(anbunStateDoc, { anbunState : false })
            }

            dispatch({ type: "PROGRESSING_PROCESS", name:`${year}年_${month}月 ${syurui}`, key:taskId })
        })
    })
    .then(() => {
        UpdateRelationTeam(nodeData, leafData, docid, month)
    })
    .then(() => console.log(`Database write successful`))
    .catch((e) => { throw `データベースの更新に失敗しました。` })       // トランザクション処理に失敗したら処理を終了する

    await DeleteAnbunLog(syurui, anbunLatestID)
    .catch((e) => { throw `按分ログの削除に失敗しました。` })

    await DeleteAllocateCost(docid, month, anbunLatestID, teamLists)
    .catch((e) => { throw `配賦された値の削除処理に失敗しました。`})
    console.log(`終了`)

    dispatch({ type: "PROGRESSING_PROCESS", name:`${year}年_${month}月 ${syurui}`, key:taskId })
    // bugfix-#277 E N D
}

async function ForOldRevertDistribution (month:string, year:string, syurui:string, dispatch:React.Dispatch<ACTION>, taskId:string) {
    let teams:any = await ReadTeams();
    let maxCount:number = Object.keys(teams).length;
    dispatch({ type:"WAIT_FUNCTIONS", maxCount:maxCount+1, status:"loading", key:taskId });

    let docid: string = year + syurui;
    await db.runTransaction(async transaction => {
        await db.collection("amoebaList").get()
        .then((querySnapshot) => {
            querySnapshot.forEach((codeDoc) => {
                let teamCode = db.collection("amoebaList").doc(codeDoc.id).collection("Saisan").doc(docid).collection('Month').doc(month);
                transaction.update(teamCode, { anbundata: firebase.firestore.FieldValue.delete() })

                // 按分済みのステータスを年月ドキュメントに書き込み
                const anbunStateDoc = db.collection("amoebaList").doc(codeDoc.id).collection("Saisan").doc(docid);
                transaction.update(anbunStateDoc, { anbunState : false })

                console.log('[ ' + codeDoc.id + ' ] success.')
                dispatch({ type: "PROGRESSING_PROCESS", name:`${year}年_${month}月 ${syurui}`, key:taskId })
            })
        })
    })
    .then(() => console.log('Revert transaction success'))
    .catch(error => { throw `按分解除に失敗しました。` })
}